@layer reset {
  .p-inputtext,
  .p-inputnumber,
  .p-input-icon-left {
    width: 100%;
    height: 40px;
    box-shadow: none;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
  }

  .p-inputtext:enabled:hover {
    border-color: #ced4da !important;
  }

  .p-inputgroup-addon {
    background: white !important;
  }
}
