@layer reset {
  .p-breadcrumb {
    border: none;
    border-radius: 6px;
    color: #666666;
    padding: 16px 0;
    background-color: transparent;
  }

  .p-breadcrumb a {
    color: #666666;
  }

  .p-breadcrumb-selected {
    color: #0e0e0e;
    font-weight: 600;
  }
}