@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

@import 'src/app/custom-components/custom.index.scss';

body {
  margin: 0;
  background-color: #f4f4f4;
}

.container {
  background-color: #f4f4f4;
  padding: 32px;
}

.o-button-danger,
.o-button-solid,
.o-button-light,
.o-button-dismiss,
.o-button-text,
.o-button-disabled {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  text-wrap: nowrap;
}

.o-button-solid {
  border: none;
  background-color: #406e7e;
  font-size: 16px;
  font-weight: 600;
  color: white;

  &:hover {
    background-color: #406e7e;
    color: white;
  }
}

.o-button-light {
  border: 1px solid #406e7e;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  color: #406e7e;
}

.o-button-text {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  color: #0e0e0e;
}

.o-button-danger {
  border: none;
  background-color: #DC2626;
  font-size: 16px;
  font-weight: 600;
  color: white;

  &:hover {
    background-color: #DC2626;
    color: white;
  }
}

// .o-button-disabled {
//     border: none;
//     background-color: var(--color-opacity-disabled);
//     color: var(--color-neutral-300);
//     font-size: 16px;
//     font-weight: 600;
//     pointer-events: none;
//     cursor: no-drop;
// }